body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast-theme--light {
  background: transparent !important;
  color: #ffffff !important;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.13) !important;
  backdrop-filter: blur(5px) !important;
  background-color: rgb(52 52 52 / 31%) !important;
}
.transform-component-module_wrapper__SPB86 {
  display: flex;
  justify-content: center;
  width: -webkit-fill-available !important;
  height: -webkit-fill-available !important;
  align-items: center;
}
.pdf-viewer {
  position: absolute;
  top: 14%;
  height: 57vh !important;
  bottom: 0;
  left: -400px;
  width: 400px;
  transition: left 0.25s ease;
  background: #fafafa;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}


.pdf-viewer-content {
  padding: 10px;
}
.hidden{
  display: none;
}
.pdf-viewer.shown {
  left: 30%
}

.upload-file-button {
  color: #fff;
  background-color: #2196F3;
}

.pdf-page-card {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}

.pdf-page-card:hover {
  background-color: #f5f5f5;
}

.pdf-page-card::after {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: transparent;
  transition: background-color 0.4s ease;
}

.pdf-page-card:hover::after {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "View Plan";
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
}

.pdf-page-card.loading:hover::after {
  content: "Loading..";
}

.pdf-page-card-header {
  margin: 5px 0px;
  font-weight: 500;
  color: aliceblue;
}

.grid-container {
  height: calc(100vh - 79px);
}