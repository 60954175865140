  .slider {
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
  }
  
  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    /* height: 300px;  */
    height: 100vh;
    /* Adjust height as needed */
  }
  .logoWithDot{
    position: absolute;
    left: 3%;
  }
  .signUpProfile{
    position: absolute;
    right: 3%;
    z-index: 1;
  }
  .starTour{
    position: absolute;
    right: 3%;
    top: 3%;
    z-index: 1;
  }
  .dots {
    position: absolute;
    top: 3vh;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%)
  }
  .zIndex{
    z-index: 44444;
  }
  .modelTitle{
    color: white;
    font-size: 2.5vh;
    font-weight: bold;
    opacity: 0.5;
  }
  .dot {
    height: 1.6vh;
    width: 1.6vh;
    margin: 0 2vh;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
  }
  /* .dot:hover {
     transform-origin: 0 0;
     transform: rotate(340deg);
    background-color: transparent;
    cursor: pointer;
    &:before{
      content: attr(data-content);
      font-weight: bold;
      color: rgb(225, 120, 13);
      cursor: pointer;
      background-color: transparent;
      font-size: 2vh;
      text-align: center;
      text-underline-position: under;
      text-decoration: underline rgb(225, 120, 13);
      position: relative;
        top: 0px;
        left: -30px;
       text-transform:uppercase;
    }
  } */
  .hoverTextShown {
    font-weight: bold;
    color: rgb(225, 120, 13);
    cursor: pointer;
    font-size: 17px;
    text-align: center;
    text-underline-position: under;
    text-decoration: underline rgb(225, 120, 13);
    padding-left: 5px;
    padding-right: 5px;
    /* font-weight:700; */
     text-transform:uppercase;
  }
  .dot.active {
    background-color: rgb(179 88 0);
  }
  /* .dot.active:hover {
    background-color: transparent
  } */
 