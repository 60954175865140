/* Small Tablets (481px to 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  /* Your styles here */
}

/* Medium Tablets (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  /* Your styles here */
}

/* Large Tablets (992px to 1279px) */
@media (min-width: 992px) and (max-width: 1279px) {
  /* Your styles here */
}

/* Small Desktops (1280px to 1440px) */
@media (min-width: 1280px) and (max-width: 1440px) {
  /* Your styles here */
}

/* Medium Desktops (1441px to 1920px) */
@media (min-width: 1441px) and (max-width: 1919px) {
  /* Your styles here */
}

/* Large Desktops (1921px and up) */
@media (min-width: 1920px) {
  .pdf-viewer.shown {
    left: 27%;
}
.body {
  min-height: 92vh !important;
}
.pdf-viewer {
  top: 15%;
}
  /* Your styles here */
}
