.canvaDataContainer {
  display: flex;
  flex-direction: column;
}
.canvaPolygonContainer {
  padding: 10px;
  border: 1px solid #cecece;
}

.canvaPolygonItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.polygonAccordionItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 5px;
  gap: 10px;
}

.polygonAccordionItem {
  display: flex;
  width: 90%;
  justify-content: space-around;
  padding: 5px;
  border: 2px solid #cecece;
  border-radius: 10px;
  cursor: pointer;
}

.polygonAccordionItem:hover {
  background-color: #e9e9e9;
}

.polygonSelected {
  background-color: #add8e6;
}
.zoomButton {
  background: transparent;
  border-width: 0;
}
.ZoomTools {
  position: absolute;
  z-index: 9;
}
/* Canvas styles */

.canvasDataBox {
  display: flex;
  width: 94%; /* // */
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  /* border: 2px solid #cecece; */
  padding: 5px;
}

.canvasDataBox::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  width: 1px;
  background-color: transparent;
}

.canvasDataBox::-webkit-scrollbar-thumb {
  border: 1px solid #160934;
  border-radius: 10px;
  background-clip: padding-box;
  background-color: black;
}

.shadowsColumn2::-webkit-scrollbar-corner {
  background: transparent;
}

.canvasDataBoxHeader {
  display: flex;
  flex-direction: column;
}

.canvasDataBoxHeaderMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .canvasDataBoxTitle {
    margin: 0;
  }
}

.canvasDataBoxButtonsContainer {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.canvasDataBoxAddGroup {
  display: flex;
  /* flex-direction: column; */
  /* grid-gap: 10px; */
  /* gap: 10px; */
  /* align-items: center; */
  margin-bottom: 12px;
  /* width: 82%;
  /* display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px; */
}

.groupCount {
  padding-bottom: 3px;
}

.groupColor {
  position: absolute;
  top: 0.5px;
  left: -5px;
  width: 12px;
  height: 12px;
  border-radius: 27%;
}

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 196px;
  height: 100%;
}

.accordionHeader {
  flex: 1 1 50%;
}

.accordionHeaderTitle {
  font-weight: 600;
}

.accordionHeaderDetails {
  display: flex;
  gap: 5px;
  align-items: center;
}

.accordionHeaderSettingsBtn {
  padding: 0;
  min-width: 0 !important;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  display: flex;
  background-color: #333;
  color: white;
  cursor: pointer;
  padding: 0px 14px;
  padding-right: 5px;
  margin-bottom: 10px;
  /* border: none; */
  border: 1px solid #444;
  flex: 1;
  align-items: center;
}

.accordion__button:hover {
  background-color: #666666;
}

.accordion__buttonArrow {
  display: inline-block;
  content: "";
  height: 7px;
  width: 7px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"] .accordion__buttonArrow,
.accordion__button[aria-selected="true"] .accordion__buttonArrow {
  transform: rotate(45deg);
  margin-bottom: 3px;
}

[hidden] {
  display: none;
}

.accordion__panel {
  animation: fadein 0.35s ease-in;
}
.modelSetting{
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}
.buttonModel{
  background-color: white  !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: .25rem !important;

}
.buttonModel:hover,
.buttonModel:focus {
  background-color: rgba(116, 110, 110, 0.25) !important;
}
.sortableItem {
  /* /* gap: 10px; */
  height: 40px;
  background-color: #1a1a1ab3;
  padding-left: 19px;
  padding-right: 6px;
  margin: 10px 2px;
  cursor: pointer;
  display: flex;
  color: white;
  align-items: center;
  border: 1px solid #312d2d;
  border-radius: 3px;
  justify-content: space-between;
  /* gap: 10px; */
}

.sortableItem:hover {
  background-color: #666666;
}
