.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.drop-zone--over {
  background-color: #232323a6 !important; /* Change background color when dragging over */
}
.loadingPerview{
  display: flex;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.pdf-page-card{
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}
.react-pdf__Document2 {
  height: 127px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}
.colorwithnumber{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-bottom: 5px;
}
.perviewCencel{
  color: white;
    opacity: 0.5;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.gifDisplay{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 64vh;
  width: 70%;
  border-radius: 12px;
  overflow: hidden;
}
/* GridPage.css */
.grid-container-background {
  /* background: linear-gradient(299deg, rgb(29 29 81 / 23%) 9%, rgb(10 10 41 / 20%) 50%, rgb(14 4 47 / 12%) 85%); */
}
.grid-container {
  /* background: linear-gradient(299deg, rgb(29 29 81 / 23%) 9%, rgb(10 10 41 / 20%) 50%, rgb(14 4 47 / 12%) 85%); */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 3px;
  /* padding: 10px; */
  overflow: auto;
  width: -webkit-fill-available;
}
.positionAbsolute{
    z-index: 1;
    position: absolute;
}
.grid-container::-webkit-scrollbar {
  width: 0px;
}
.grid-conver-img{
  height: 135%;
  object-fit: cover;
}
.grid-contanir-img{
  height: 100px;
  width: -webkit-fill-available;
  
}
.grid-page-title {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the title horizontally */
  padding: 2px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  position: relative;
}
.tick-mark {
  position: absolute;
  left: 0px;
  top: 0px;
}

.title {
  padding: 2px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.grid-item:hover {
  border: 2px solid rgb(165, 154, 0);
}
.grid-item-active {
  border: 2px solid rgb(30, 202, 7) !important;
}
.pageItem{
  height: 15vh;
}
/* .pageItem:hover{
  transform: scale(1.5);
  transition: transform .4s; 
  display: flex;
  align-items: center;
  justify-content: center;
} */
.grid-item {
  border: 2px solid #ffffff;
  background-color: #ffffff;
  cursor: pointer;
  height: 26vh;
  /* //160px; */
  
  overflow: hidden;
  border-radius: 12px;
  text-align: center;
}
.react-pdf__Document {
  /* background: linear-gradient(299deg, rgb(29 29 81 / 23%) 9%, rgb(10 10 41 / 20%) 50%, rgb(14 4 47 / 12%) 85%); */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 3px;

  overflow: auto;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.react-pdf__Document3 {
  height: 127px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.react-pdf__Document2::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.react-pdf__Document2::-webkit-scrollbar-thumb {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  background-clip: padding-box;
  background-color: black;
}
.react-pdf__Document3 {
  height: 127px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.react-pdf__Document::-webkit-scrollbar {
  width: 0px;
}

.react-pdf__Document::-webkit-scrollbar-thumb {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  background-clip: padding-box;
  background-color: black;
}
.react-pdf__Page__canvas {
 width: -webkit-fill-available !important;
   height: 22vh  !important;
}
.overflowHidden{
  padding-left: 7.5px;
  padding-right: 7.5px;
}
a {
  color: transparent;
  text-decoration: none;
  /* no underline */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.shadowsColumn2 {
  scrollbar-color: transparent transparent;
  height: 70vh;
  overflow: scroll;
  padding: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.13);
  /* backdrop-filter: blur(5px); */
  background-color: rgb(52 52 52 / 31%);
  border-radius: 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}