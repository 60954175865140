.body {
  min-height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex !important;
}
.body-main{
  min-height: 91vh;
  flex-direction: column;
  align-items: center;
  margin-top: 8.4vh;
  /* justify-content: end; */
  display: flex !important;
}
.body90{
  min-height: 90vh;
}
.avatar {
  box-sizing: border-box;
  /* border-radius: 12%; */
  /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
  transform: translateY(0px);
  /* background-color: rgba(69, 69, 61, 0.6); */
  animation: float 6s ease-in-out infinite;
}
.classScaleModal{
  position: relative;
}
.shadowsColumn{
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.13);
/* backdrop-filter: blur(5px); */
background-color: rgb(52 52 52 / 31%);
border-radius: 12px;
}
.grid-center{
  display: grid;
  justify-content: center;
  padding: 7px;
}
.grid-center-main{
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  overflow: hidden;
}
.logo{
  /* height: 65px */
  /* opacity: 0.8; */
  height: 6.8vh;
  padding: 8px 0px;
  margin-left: -10px;
}
.ai-data{
  overflow: hidden;
  flex: 2;  
  padding: 10px;
  text-align: center; 
}

.pdf-data{
  flex: 6;  
  text-align: center;
  padding: 10px;

  margin-left: 60px;
  margin-right: 60px;
}
.mainApp{
  margin-top: 12px;
}
.pdf-data2{
  flex: 6;  
  text-align: center;
  /* margin-left: 60px; */
  /* padding: 10px; */
  /* overflow: scroll; */
  padding: 10px;
  /* margin-right: 60px; */
}
.options-data{
  overflow: hidden;
  flex: 2;  
  padding: 10px;
  text-align: center; 
}
.options-data2{
  /* overflow: hidden; */
  /* flex: 1;   */
  padding: 10px;
}
@keyframes float {
  0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translateY(0px);
  }
  50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translateY(-5px);
  }
  100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translateY(0px);
  }
}


.color-white{
  color: "#FFFFFF";
}
.footer0{
  position: absolute;
  bottom: 0;
  }
 .boxBorder{
  background-color: #222222;
  color: "#FFFFFF";
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
 }
 .video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1; /* Place the video behind other content */
}

video {
  object-fit: cover; /* Stretch video to fill container */
  width: 100%;
  height: 100%;
}
.containerColl {
  display: flex; /* Makes the container a flexbox */
  height: 73vh;
  /* //60 */
}
.container {
  display: flex; /* Makes the container a flexbox */
  padding-left: 40px;
  padding-right: 24px;
}
.tryFree{
  color: rgb(225 120 13) !important;
    border-radius: 27px!important;
    padding: 15px!important;
    height: 46px!important;
    border-width: 2.4px!important;
    font-weight: 600!important;
    border-color: rgb(225 120 13)!important;
}
.center {
  flex: 2;  
  text-align: center; /* Optional: Center content within each column */
}
.right {
  flex: 6;  
  text-align: right; /* Optional: Center content within each column */
}
.left {
  flex: 6;  
  text-align: left; /* Optional: Center content within each column */
}
.sign {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30%;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    rgb(202 90 12),
    transparent
  );
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 2.5em;
  color: #ffe6ff;
  /* text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #d1a427,
    -0.2rem 0.1rem 1rem #d1a427, 0.2rem 0.1rem 1rem #d1a427,
    0 -0.5rem 2rem rgb(202 90 12), 0 0.5rem 3rem rgb(202 90 12); */
  animation: shine 2s forwards, flicker 3s infinite;
}

/* /// for left right animation */

/* sd// */


@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #d1a427,
      -0.2rem 0.1rem 1rem #d1a427, 0.2rem 0.1rem 1rem #d1a427,
      0 -0.5rem 2rem rgb(202 90 12), 0 0.5rem 3rem rgb(202 90 12);
  }
  28%,
  33% {
    color: #d1a427;
    text-shadow: none;
  }
  82%,
  97% {
    color: rgb(202 90 12);
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #d1a427,
      -0.2rem 0.1rem 1rem #d1a427, 0.2rem 0.1rem 1rem #d1a427,
      0 -0.5rem 2rem rgb(202 90 12), 0 0.5rem 3rem rgb(202 90 12);
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
